import React, { useEffect, useState } from 'react'
import '../styles/navbar.css'

import logo from '../static/pictures/logo.webp'
import hamburger from '../static/pictures/more.png'
import menu from '../static/pictures/menu.png'
import { Link, useNavigate } from "react-router-dom";

import { useSpring, useTransition, animated } from 'react-spring'
import Sidebar from './Sidebar'

import { getEvents } from '../functions/getEvents'
import { filterEvents } from '../functions/filterEvents'

import { seasonChecking } from '../functions/seasonChecking'

const Navbar = ({ kontaktRef, cennikRef, instruktoriRef, uvodRef }) => {

  const [openArchive, setOpenArchive] = useState(0);
  const [openCost, setOpenCost] = useState(0);
  const [openGallery, setOpenGallery] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [openSideBar, setOpenSideBar] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [eventDataNew, setEventDataNew] = useState([]);
  const [eventDataOld, setEventDataOld] = useState([]);

  const isSummer = seasonChecking();

  const navigate = useNavigate();

  const dropdownManagement = (value) => {
    if (value === 1) {
      if (openArchive === 0) {
        setOpenArchive(1);
        setOpenCost(0);
        setOpenGallery(0);
      } else setOpenArchive(0);
    } else if (value === 3) {
      if (openGallery === 0) {
        setOpenGallery(1);
        setOpenArchive(0);
        setOpenCost(0);
      } else setOpenGallery(0);
    }
  }

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }
  // create an event listener + startup configuration for navbar onload.
  useEffect(() => {

    async function OnStart() {
      const eventData = await getEvents();
      const filteredDates = filterEvents(eventData.data, 0, 4);
      const filteredDatesOld = filterEvents(eventData.data, 1, 4);
      setEventDataNew(filteredDates);
      setEventDataOld(filteredDatesOld);
    }

    handleResize();
    window.addEventListener("resize", handleResize)
    OnStart();
  }, []);

  return (
    <>
      {isMobile === false ? <div className="navbar">
        <div className="logo">
          <img style={{ cursor: 'pointer' }} onClick={() => uvodRef.current.scrollIntoView({ behavior: "smooth" })} src={logo} alt="logo" />
        </div>
        <ul>

          {openArchive === 1 ? <div className="dropdown">
            {eventDataOld.length !== 0 ? eventDataOld.map((event, index) => <div onClick={() => { navigate(`archive-item/${event.id}`); setOpenArchive(0) }} className="item" key={index}>{event.header}</div>) : <p style={{ textAlign: 'center', color: 'white' }}>Žiadne podujatia nie sú v archíve!</p>}
            {eventDataOld.length !== 0 ? <div className="item" onClick={() => { navigate(`archive`); setOpenArchive(0) }} style={{ background: '#25aae1', color: 'white' }}>Zobraziť archív podujatí</div> : ""}
          </div> : ""}


          {openGallery === 1 ? <div className="dropdown">
            {eventDataNew.length !== 0 ? eventDataNew.map((event, index) => <div onClick={() => { navigate(`event-item/${event.id}`); setOpenGallery(0) }} className="item" key={index}>{event.header}</div>) : <p style={{ textAlign: 'center', color: 'white' }}>Žiadne podujatia nie sú pripravované!</p>}
            {eventDataNew.length !== 0 ? <div onClick={() => { navigate(`events`); setOpenGallery(0) }} className="item" style={{ background: '#25aae1', color: 'white' }}>Zobraziť všetky nadchádzajúce podujatia</div> : ""}
          </div> : ""}

          <li onClick={() => uvodRef.current.scrollIntoView({ behavior: "smooth" })}><Link to='/'>Úvod</Link></li>
          {/*  
           <li onClick={() => dropdownManagement(1)}>
            <a style={{ opacity: openArchive === 1 ? '0.4' : '1' }}>▾Archív</a>
          </li>
          */}
          <li onClick={() => dropdownManagement(3)}>
            <a style={{ opacity: openGallery === 1 ? '0.4' : '1' }}>▾Podujatia</a>
          </li>
          {!isSummer ? <li onClick={() => cennikRef.current.scrollIntoView({ behavior: "smooth" })}><Link id="gtm-cennik" to=''>Cenník</Link></li> : ""}
          {!isSummer ? <li onClick={() => instruktoriRef.current.scrollIntoView({ behavior: "smooth" })}><Link to=''>Inštruktori</Link></li> : ""}
          <li onClick={() => kontaktRef.current.scrollIntoView({ behavior: "smooth" })}><Link to=''>Kontakt</Link></li>
        </ul>
        <Sidebar setOpenSideBar={setOpenSideBar} openSideBar={openSideBar} isMobile={isMobile} />

      </div> :
        <div className="navbar">
          <div className="logo" id="newPadding">
            <img style={{ cursor: 'pointer' }} onClick={() => navigate('/')} src={logo} alt="logo" id="newHeight" />
          </div>
          <div className="hamburger" style={{ paddingRight: '20px' }}>
            <img src={hamburger} onClick={() => setOpenSideBar(true)} alt="hamb" height="25px" />
          </div>
          <Sidebar setOpenSideBar={setOpenSideBar} openSideBar={openSideBar} isMobile={isMobile} />
        </div>
      }
    </>
  )
}

export default Navbar