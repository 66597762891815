import axios from 'axios'

//Function responsible for season checking,
//if returning 'true', it's summer season,
//if returning 'false', it's winter season.
export const seasonChecking = () => {

    const currentTime = new Date(); //Current date
    const currentYear = currentTime.getFullYear().toString();
    
    const summerStartDate = new Date(`15 Apr ${currentYear}`)   //Start of the summer season (15 Apr)
    const summerEndDate = new Date(`15 Oct ${currentYear}`)     //End of the summer season (15 Oct)
    
    if(currentTime >= summerStartDate && currentTime <= summerEndDate) 
        return true;
    else
        return false;
}