import axios from 'axios'
import { getToken } from './loginVerification'

export const addDaily = async(text) => {
    const tokenData = await getToken();
    let data;
    if(tokenData.ok) {
        const instance = axios.create({
            headers: {'Authorization': 'Bearer '+ tokenData.token}
        });
        await instance.post(`${process.env.REACT_APP_API_HOST}/information/daily-info/add`, {
            text: text
        }).then(resp => {
            data = resp.data;
        });
    }
    return data;
}