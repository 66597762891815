import axios from 'axios'
import { getToken } from './loginVerification'

export const addInformation = async(header, text) => {
    const tokenData = await getToken();
    let data;
    if(tokenData.ok) {
        const instance = axios.create({
            headers: {'Authorization': 'Bearer '+ tokenData.token}
        });
        await instance.post(`${process.env.REACT_APP_API_HOST}/information/add`, {
            header: header,
            text: text
        }).then(resp => {
            data = resp.data;
        });
        return data;
    }
}