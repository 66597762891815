import axios from 'axios';
import {getToken} from '../functions/loginVerification'

export const logout = async(navigate, whereto) => {
    const tokenData = await getToken();
    let isOk;
    if(tokenData.ok) {
        const instance = axios.create({
            headers: {'Authorization': 'Bearer '+ tokenData.token}
        });

        await instance.get(`${process.env.REACT_APP_API_HOST}/user/logout`).then(resp => {
            isOk = resp.data.ok;
        });
        console.log(isOk);
        if(isOk) {
            return navigate(whereto);
        }
    }
}