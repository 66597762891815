import axios from 'axios'

export const getToken = async() => {
    var cook_ok = false;
    var tokenRetrieved = "";
    var data = [];
    await axios.get(`${process.env.REACT_APP_API_HOST}/user/cookies`).then(async(resp) => {
        cook_ok = resp.data.cookieOk; 
        tokenRetrieved = resp.data.tokenRetrieved;
        if(cook_ok === true) {
            await axios.post(`${process.env.REACT_APP_API_HOST}/user/verify`, {token: tokenRetrieved}).then((respTwo) => {
                cook_ok = respTwo.data.tokenData.tokenOk; 
                if(cook_ok === true) {
                    data = respTwo.data.tokenData.data;
                } else {data = []; cook_ok = false};
            });
        } else {data = []; cook_ok = false};
    });
    return {ok: cook_ok, data: data, token: tokenRetrieved};
}