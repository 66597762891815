import React, { useEffect, useState } from 'react'
import '../styles/footer.css'
import logo from '../static/pictures/logo.webp'
import insta from '../static/pictures/instagram.png'
import facebook from '../static/pictures/facebook.png'
import { Link } from 'react-router-dom'

const Footer = () => {

  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  useEffect(() => {
    handleResize();
  });

  return (
    <div className="footer">
      <div className="content">
        <div className="item" id="logo">
          <img src={logo} />
        </div>
        <div className="item">

          <div className="subitem">
            <div style={{ margin: '0 auto' }}>
              <img src={insta} style={{ cursor: 'pointer' }} height="30px" />
              <img src={facebook} style={{ cursor: 'pointer' }} onClick={() => window.location.href = 'https://www.facebook.com/profile.php?id=100043234313913'} height="30px" />
            </div>
          </div>
          <div className="subitem">
            {isMobile ? <small>2022, Skiland s.r.o. Všetky práva vyhradené<br />Programmed by Tomáš Gergely</small> : <small>2022, Skiland s.r.o. Všetky práva vyhradené<br />Programmed by Tomáš Gergely</small>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer