import React, { useEffect, useState } from 'react';
import { getCameraPics } from '../functions/getCameraPics';
import '../styles/cameras.css';
import DocumentTitle from '../functions/DocumentTitle';


const Cameras = () => {
  DocumentTitle("Kamery - Skiland Stará Myjava");
  const [cameraPics, setCameraPics] = useState([]);
  const [imgCamera, setImgCamera] = useState();

  const fetchCameraData = async () => {
    try {
      const cameraData = await getCameraPics();
      setCameraPics(cameraData);

      if (cameraData.length === 0) {
        console.warn('No camera data available.');
        return;
      }

      const url = process.env.REACT_APP_API_HOST + '/static/webp/' + cameraData[0];

      const res = await fetch(url);
      if (!res.ok) {
        throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
      }

      const imageBlob = await res.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);
      setImgCamera(imageObjectURL);

      console.log('Image loaded successfully');
    } catch (error) {
      console.error('Error fetching image:', error);
      // Handle errors, e.g., retry or display a placeholder image
    }
  };



  useEffect(() => {
    const loadData = async () => {
      await fetchCameraData();
    };

    // Initial load
    loadData();

    // Cameras refresh every 10 seconds
    const intervalId = setInterval(loadData, 10000);

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="kamery">
      <h1 style={{ color: 'white', textAlign: 'center' }}>Záznamy kamier</h1>
      <p style={{ color: 'white', textAlign: 'center' }}>
        Ak záznamy preblikávajú, poprípade nie sú vidno, načítavajú sa. Prosím, počkajte!
      </p>
      <img src={imgCamera} alt="Camera" />
    </div>
  );
};

export default Cameras;
