import axios from 'axios'
import { getToken } from './loginVerification'

export const addArchivePhotos = async(formData) => {
    const tokenData = await getToken();
    let data;
    if(tokenData.ok) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_HOST}/events/add-photo-archive`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data", 'Authorization': 'Bearer ' + tokenData.token }
          }).then(resp => data = resp.data);
        return data;
    }
}