import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import '../styles/home.css'
import video from '../static/videos/skiland.mp4'
import next from '../static/pictures/next.png'
import warning from '../static/pictures/warning.png'
import 'animate.css'
import Information from '../components/Information'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import eltime from '../static/pictures/partneri/eltime.png'
import euro from '../static/pictures/partneri/euro.png'
import phone from '../static/pictures/phone-call.png'
import email from '../static/pictures/email.png'
import gps from '../static/pictures/gps.png'
import cloud from '../static/pictures/cloudy.png'
import wind from '../static/pictures/wind.png'
import temp from '../static/pictures/temperature.png'
import humi from '../static/pictures/humidity.png'

import { getEvents } from '../functions/getEvents'
import { filterEvents } from '../functions/filterEvents'
import { trimText } from '../functions/trimText'
import { fixedDate } from '../functions/dates'
import { getImportant } from '../functions/getImportant'
import { getWeatherData } from '../functions/getWeatherData'
import { getAllInstructors } from '../functions/getAllInstructors'
import { filterInstructors } from '../functions/filterInstructors'
import { seasonChecking } from '../functions/seasonChecking'
import DocumentTitle from '../functions/DocumentTitle';

import leto from '../static/pictures/leto_foto.webp'

const Home = ({ kontaktRef, cennikRef, instruktoriRef, uvodRef }) => {
  DocumentTitle("Skiland Stará Myjava");

  const isSummer = seasonChecking();

  const vidRef = useRef();

  useEffect(() => {
    if (!isSummer) {
      vidRef.current.play();
    }
  }, []);


  //Get all necessary things..

  //Order of tickets or skiing
  const [selling, setSelling] = useState(0);
  const [sellingText, setSellingText] = useState("Lístky");

  //Backend content variables
  const [information, setInformation] = useState([]);
  const [infoPage, setInfoPage] = useState(1);
  const [sortedInfo, setSortedInfo] = useState([]);

  //Events filtered (old and new)
  const [eventsNew, setEventsNew] = useState([]);
  const [eventsOld, setEventsOld] = useState([]);

  const [isMobile, setIsMobile] = useState(false);
  const [important, setImportant] = useState([]);

  const [weather, setWeather] = useState({});
  const [loaded, setLoaded] = useState(false);

  const [instructorsLyzovanie, setInstructorsLyzovanie] = useState([]);
  const [instructorsSnowboard, setInstructorsSnowboard] = useState([]);

  const navigate = useNavigate();

  const sellingChoice = () => {
    if (!selling) {
      setSelling(1);
      setSellingText("Požičovňa")
    } else {
      setSelling(0);
      setSellingText("Lístky");
    }
  }

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  useEffect(() => {
    // Show a notification on page load
    toast.warn('Konštrukčný problém s vlekom môže spôsobiť znečistenie odevu. Odporúčame tmavé lyžiarske nohavice a ďakujeme za pochopenie. Viac informácií v sekcií Upozornenia.', {
      position: "bottom-left",
      autoClose: 15000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    }
    );
  }, []); // Empty dependency array means this effect runs only once on mount


  useEffect(() => {
    async function OnStart() {
      const weatherData = await getWeatherData();
      setWeather(weatherData.data);
      const eventData = await getEvents();
      const filteredNew = filterEvents(eventData.data, 0, 2);
      const filteredOld = filterEvents(eventData.data, 1, 2);
      const important = await getImportant();

      const instructorsData = await getAllInstructors();
      const filterInstructorsLyzovanie = filterInstructors(instructorsData.instructors, 0);
      const filterInstructorsSnowboard = filterInstructors(instructorsData.instructors, 1);
      setInstructorsLyzovanie(filterInstructorsLyzovanie);
      setInstructorsSnowboard(filterInstructorsSnowboard);

      setImportant(important.data);
      setEventsNew(filteredNew);
      setEventsOld(filteredOld);
      setLoaded(true);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    OnStart();
    axios.get(`${process.env.REACT_APP_API_HOST}/information/all`).then((resp) => { setInformation(resp.data); setSortedInfo([resp.data[0], resp.data[1]]) });
    //axios.get(`${process.env.REACT_APP_API_HOST}/events`).then(resp => {setEventInfo(resp.data.data)});
  }, []);

  return (
    <div className="home-page">
      <ToastContainer />
      <div className='video-show' ref={uvodRef} style={isSummer ? { backgroundImage: `url(${leto})`, backgroundSize: 'cover', zIndex: 100 } : {}}>
        {console.log(isSummer)}

        {!isSummer ?
          <video
            id="myVideo"
            src={video}
            ref={vidRef}
            muted
            autoPlay
            loop
            playsInline
          /> : ""}

        <div className='cover-video'>
          <div className="content-box">
            <h1 className="animate__animated animate__slideInDown">Víta Vás Skiland</h1>
            <p className="animate__animated animate__slideInDown">Lyžiarske stredisko Skiland Stará Myjava</p>
          </div>
        </div>
        {console.log(important)}

        {important.length !== 0 ? <div className="dolezite">
          <h2 style={isSummer ? { color: '#84d9ad' } : { color: '#25aae1' }}>{important.header}</h2>
          <p>{important.content}</p>
        </div> : ""}

        {loaded ? <div className="weather">
          <h2 style={isSummer ? { color: '#84d9ad' } : { color: '#25aae1' }}>Počasie</h2>
          <div className="weather-item">
            <img src={temp} alt="" />
            <p>{weather.main.temp} °C</p>
          </div>
          <div className="weather-item">
            <img src={wind} alt="" />
            <p>{weather.wind.speed} m/s</p>
          </div>
          <div className="weather-item">
            <img src={humi} alt="" />
            <p>{weather.main.humidity} %</p>
          </div>
        </div> : ""}
      </div>

      <div className='information-tab'>

        <div className="info" style={{ flexBasis: '50%', padding: '30px' }}>
          <div className="information-header" style={isSummer ? { background: '#84d9ad' } : { background: '#25aae1' }}>
            <div className="information-text">Informácie</div>
          </div>

          <div className="information-content">
            {sortedInfo.map((info, index) => <Information color={1} key={index} header={info.header} text={info.text} date={info.createdAt} />)}
          </div>
        </div>

        <div className="upozornenia" style={{ flexBasis: '50%', padding: '30px' }}>
          <div className="information-header" style={{ width: '180px', background: '#ff6174' }}>
            <div className="information-text" style={{ color: 'black' }}>Upozornenia</div>
          </div>

          <div className="information-content">
            <Information color={2} key={1} header="Konštrukčné problémy vlekov" text="Vážení návštevníci, veľmi nás to mrzí, ale nakoľko vlek je technické zariadenie, musí byť mazané a pri odmäkových teplotách sa môže v malom množstve uvoľňovať mazivo aj s vyzrážanou vodou zo závesu a tým môže prísť k znečisteniu lyžiarskeho odevu. S týmto problémom bojujú viaceré lyžiarske strediská a vopred sa chceme ospravedlniť, ale tento problém nie je náš, je konštrukčný. Odporúčame tmavé lyžiarske nohavice. Za pochopenie vopred ďakujeme" date="1.12.2022" />
            <Information color={2} header="Zákon prikazuje!" text="Podľa novely Zákona c. 544/2002 Z.z. o HZS, § 8a je ,,lyžiar mladší ako 15 rokov povinný na lyžiarskej trati chrániť si hlavu riadne upevnenou ochrannou prilbou''." date="1.12.2022" />
          </div>
        </div>
      </div>

      {!isSummer ? <div className="cennik-tab" ref={cennikRef}>
        <div className="cennik-header" id="cennik-id" style={isSummer ? { background: '#84d9ad' } : { background: '#25aae1' }}>
          <div className="cennik-text">Cenník</div>
        </div>
        <div className="cennik-table">
          <table>
            <tr>
              <th style={isSummer ? { color: '#84d9ad' } : { color: '#25aae1' }}>Lístok</th>
              <th style={isSummer ? { color: '#84d9ad' } : { color: '#25aae1' }}>Dospelý</th>
              <th style={isSummer ? { color: '#84d9ad' } : { color: '#25aae1' }}>Dieťa</th>
            </tr>
            <tr>
              <td>Celodenný</td>
              <td>25,00€</td>
              <td>20,00€</td>
            </tr>
            <tr>
              <td>1 hodina</td>
              <td>15,00€</td>
              <td>12,00€</td>
            </tr>
            <tr>
              <td>2 hodiny</td>
              <td>18,00€</td>
              <td>14,00€</td>
            </tr>
            <tr>
              <td>3 hodiny</td>
              <td>20,00€</td>
              <td>16,00€</td>
            </tr>
            <tr>
              <td>4 hodiny</td>
              <td>22,00€</td>
              <td>17,00€</td>
            </tr>
            <tr>
              <td>Rodinný 2+1</td>
              <td>60,00€</td>
              <td>X</td>
            </tr>
            <tr>
              <td>Rodinný 2+2</td>
              <td>80,00€</td>
              <td>X</td>
            </tr>
            <tr>
              <td>1 jazda</td>
              <td>4,00€</td>
              <td>X</td>
            </tr>
            <tr>
              <td>Celosezónny</td>
              <td>250,00€</td>
              <td>X</td>
            </tr>
          </table>
        </div>

        <div className="cennik-informacie">
          <p id="info">
            Záloha za kartu - 2,00 €<br />
            Deti do 5 rokov – zdarma (platí iba pri preukázaní veku dieťaťa preukazom poistenca)<br />
            Pri detskom lístku je potreba preukázať vek dieťaťa preukazom poistenca<br />
            Pri seniorskom lístku je potreba preukázať vek občianskym preukazom<br />
            Pri rodinných lístkoch je podmienkou predaja zhodné priezviská, t.j.  predloženie OP rodičov a preukazov poistenca detí
            <div id="important"><img src={warning} /><span>BEZ PREDLOŽENIA TÝCHTO DOKLADOV NIE JE MOŽNÉ LÍSTKY VYDAŤ</span></div>
          </p>
        </div>

        {isSummer === false ? <div className="cennik-table">
          {isMobile ? <p style={{ color: 'white', textAlign: 'left' }}>1. Lyže<br />2. Lyže detské<br />3. Lyžiarske topánky<br />4. Lyžiarske topánky detské<br />5. Lyžiarske palice<br />6. Lyžiarske palice detské<br />7. Prilba<br />8. Lyžiarska komplet výstroj<br />9. Lyžiarska komplet výstroj detská<br />10. Snowboard<br />11. Snowboard topánky<br />12. Snowboard komplet<br />13. Uzamykateľná skrinka</p> : ""}
          <table style={{ width: '90%' }}>
            <tr>
              <th colspan="6" >Požičovňa</th>
            </tr>
            <tr>
              <th></th>
              <th>1 hod.</th>
              <th>2 hod.</th>
              <th>3 hod.</th>
              <th>4 hod.</th>
              <th id="breakWord">Deň</th>
            </tr>
            <tr>
              <td>{isMobile ? "1." : "Lyže"}</td>
              <td>6€</td>
              <td>7€</td>
              <td>8€</td>
              <td>9€</td>
              <td>12€</td>
            </tr>
            <tr>
              <td>{isMobile ? "2." : "Lyže detské"}</td>
              <td>4€</td>
              <td>5€</td>
              <td>6€</td>
              <td>7€</td>
              <td>9€</td>
            </tr>
            <tr>
              <td>{isMobile ? "3." : "Lyž. topánky"}</td>
              <td>6€</td>
              <td>7€</td>
              <td>8€</td>
              <td>9€</td>
              <td>12€</td>
            </tr>
            <tr>
              <td>{isMobile ? "4." : "Lyž. topánky detské"}</td>
              <td>3€</td>
              <td>4€</td>
              <td>5€</td>
              <td>6€</td>
              <td>7€</td>
            </tr>
            <tr>
              <td>{isMobile ? "5." : "Lyž. palice"}</td>
              <td>4€</td>
              <td>5€</td>
              <td>6€</td>
              <td>7€</td>
              <td>8€</td>
            </tr>
            <tr>
              <td>{isMobile ? "6." : "Lyž. palice detské"}</td>
              <td>2€</td>
              <td>3€</td>
              <td>4€</td>
              <td>5€</td>
              <td>6€</td>
            </tr>
            <tr>
              <td>{isMobile ? "7." : "Prilba"}</td>
              <td>3€</td>
              <td>4€</td>
              <td>5€</td>
              <td>6€</td>
              <td>8€</td>
            </tr>
            <tr>
              <td>{isMobile ? "8." : "Lyž. komplet výstroj"}</td>
              <td>12€</td>
              <td>15€</td>
              <td>18€</td>
              <td>21€</td>
              <td>26€</td>
            </tr>
            <tr>
              <td>{isMobile ? "9." : "Lyž. komplet výstroj detská"}</td>
              <td>6€</td>
              <td>9€</td>
              <td>12€</td>
              <td>15€</td>
              <td>18€</td>
            </tr>
            <tr>
              <td>{isMobile ? "10." : "Snowboard"}</td>
              <td>12€</td>
              <td>13€</td>
              <td>14€</td>
              <td>15€</td>
              <td>17€</td>
            </tr>
            <tr>
              <td>{isMobile ? "11." : "Snowboard topánky"}</td>
              <td>7€</td>
              <td>8€</td>
              <td>9€</td>
              <td>10€</td>
              <td>14€</td>
            </tr>
            <tr>
              <td>{isMobile ? "12." : "Snowboard komplet"}</td>
              <td>15€</td>
              <td>17€</td>
              <td>19€</td>
              <td>21€</td>
              <td>26€</td>
            </tr>
            <tr>
              <td>{isMobile ? "13." : "Uzamyk. skrinka"}</td>
              <td>2€</td>
              <td>2€</td>
              <td>2€</td>
              <td>2€</td>
              <td>2€</td>
            </tr>
          </table>
        </div> : ''}

        {isSummer === false ? <div className="cennik-informacie">
          <p id="info">
            <div id="important"><img src={warning} />Vážení zákazníci, v prípade nevrátenia vypožičaných vecí v dohodnutom čase za 15 minút omeškania rátame 1 hodnpm !</div>
          </p>
        </div> : ''}

      </div> : ""}

      {eventsNew.length > 0 || eventsOld.length > 0 ? <div className="podujatia">
        <div className="podujatia-header" style={isSummer ? { background: '#84d9ad' } : { background: '#25aae1' }}>
          <div className="podujatia-text">Podujatia</div>
        </div>
        <div className="podujatia-mainflex">
          {eventsNew.length > 0 ? <div className="podujatia-mainflex-new">
            <h2 style={isSummer ? { color: '#84d9ad' } : { color: '#25aae1' }}>Najbližšie podujatia</h2>

            {eventsNew.map((event, index) =>
              <div key={index} onClick={() => navigate(`event-item/${event.id}`)} className="podujatie-item">
                <img src={process.env.REACT_APP_API_HOST + "/static/" + event.photos[0]} alt="test" />
                <div className="podujatieItem-info">
                  <h2>{event.header}</h2>
                  <small style={{ color: 'gray' }}>Dátum začiatku: {fixedDate(event.startDate)}</small>
                  <p>{trimText(event.text, 150)}</p>
                </div>
              </div>)}
            <div className="more" style={{ textAlign: 'center' }}>
              <Link to="events" style={{ color: 'white', margin: '0 auto' }}>Zobraziť podujatia</Link>
            </div>
          </div> : ""}
          {eventsOld.length > 0 ? <div className="podujatia-mainflex-old">
            <h2 style={isSummer ? { color: '#84d9ad' } : { color: '#25aae1' }}>Nedávne podujatia</h2>
            {eventsOld.map((event, index) =>
              <div key={index} onClick={() => navigate(`archive-item/${event.id}`)} className="podujatie-item">
                <img src={process.env.REACT_APP_API_HOST + "/static/" + event.photos[0]} alt="test" />
                <div className="podujatieItem-info">
                  <h2>{event.header}</h2>
                  <small style={{ color: 'gray' }}>Konané dňa: {fixedDate(event.startDate)}</small>
                  <p>{trimText(event.text, 150)}</p>
                </div>
              </div>)}
            <div className="more" style={{ textAlign: 'center' }}>
              <Link to="archive" style={{ color: 'white', margin: '0 auto' }}>Zobraziť archív</Link>
            </div>
          </div> : ""}
        </div>
      </div> : ""}

      {isSummer === false ? <div className="instruktor-tab" ref={instruktoriRef}>
        <div className="instruktor-header">
          <div className="instruktor-text">Inštruktori</div>
        </div>
        <div className="instruktor-table">
          <table>
            <tr>
              <th colspan="2">Lyžovanie</th>
            </tr>
            <tr>
              <th>Meno</th>
              <th>Telefón</th>
            </tr>
            {instructorsLyzovanie.map((instruktor, index) => <tr tabIndex={index}>
              <td>{instruktor.meno}</td>
              <td>{instruktor.telefon}</td>
            </tr>)}
          </table>
          <table>
            <tr>
              <th colspan="2">Snowboarding</th>
            </tr>
            <tr>
              <th>Meno</th>
              <th>Telefón</th>
            </tr>
            {instructorsSnowboard.map((instruktor, index) => <tr tabIndex={index}>
              <td>{instruktor.meno}</td>
              <td>{instruktor.telefon}</td>
            </tr>)}
          </table>
        </div>
      </div> : ''}

      <div className="partneri">
        <div className="partneri-header" style={isSummer ? { background: '#84d9ad' } : { background: '#25aae1' }}>
          <div className="partneri-text">Partneri</div>
        </div>
        <h2 style={{ color: 'white', textAlign: 'center' }}>Tieto firmy o nás hovoria</h2>
        <div className="partners-logos">
          <img style={{ borderRadius: '0px' }} src={eltime} />
          <img style={{ borderRadius: '0px', height: '50px' }} src={euro} />
        </div>
      </div>
      <div className="lokacia">
        <div className="lokacia-header" style={isSummer ? { background: '#84d9ad' } : { background: '#25aae1' }}>
          <div className="lokacia-text">Poloha</div>
        </div>
        <h2 id="header">Chcete vedieť, kde sa nachádzame? Pozrite sa tu</h2>
        <div className="google-maps">
          <iframe src="https://maps.google.com/maps?width=100%25&amp;hl=en&amp;q=skiland%20star%C3%A1%20myjava+&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="100%" height="600" style={{ border: '0px', margin: '0 auto', borderRadius: '20px' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div className="kontakt" ref={kontaktRef}>
        <div className="kontakt-header" style={isSummer ? { background: '#84d9ad' } : { background: '#25aae1' }}>
          <div className="kontakt-text">Kontakt</div>
        </div>
        <div className="contant-content">
          <h3>Telefónne číslo</h3>
          <div className='contact-telephone'>
            <img src={phone} alt="phone" />
            <p>+421905250653</p>
          </div>
          <h3>Emailová adresa</h3>
          <div className='contact-telephone'>
            <img src={email} alt="phone" />
            <p>skilandsro@gmail.com</p>
          </div>
          <h3>Adresa miesta</h3>
          <div className='contact-telephone'>
            <img src={gps} alt="phone" />
            <p>Stará Myjava 411, Slovensko</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home