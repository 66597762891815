import axios from 'axios'

export const getWeatherData = async() => {
    let data;

    await axios.get(`${process.env.REACT_APP_API_HOST}/weather/getweather`).then(resp => {
        data = resp.data;
    });

    return data;
}