import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getEventByPk } from '../functions/getEventById';
import '../styles/archive.css'

const ArchiveItem = () => {
  const { id } = useParams();
  const [eventData, setEventData] = useState([]);
  const [canDisplay, setCanDisplay] = useState(false);

  useEffect(() => {
    const OnStart = async() => {
      const data = await getEventByPk(Number(id));
      setEventData(data.data);
      setCanDisplay(true);
    }
    OnStart();
  }, []);

  return (
    <div className="archive-item">
      <h1>{eventData.header}</h1>
      <div className="archive-item-desc">
        <p>{eventData.text}</p>
      </div>
      <div className="photos-showcase">
          {canDisplay ? eventData.photos.map((photo, index) => <img src={process.env.REACT_APP_API_HOST + "/static/" + photo}/>) : ""}
      </div>
    </div>
  )
}

export default ArchiveItem