import axios from 'axios'

export const trimText = (textToTrim, numOfChars) => {
    let newString = "";
    let numChars = numOfChars;

    if(numChars > textToTrim.length)
    numChars = textToTrim.length;

    for (let x = 0; x < numChars; x++)
        newString += textToTrim[x];

    if(numChars < textToTrim.length)
        newString += " ...";

    return newString;
}