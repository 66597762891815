import axios from 'axios';
import { getToken } from './loginVerification';

const MAX_RETRIES = 3; // Maximum number of retries

export const getCameraPics = async () => {
    let data;
    let retries = 0;

    while (retries < MAX_RETRIES) {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_HOST}/cameras/newest`);
            data = response.data;

            if (data.length > 0) {
                // Data received successfully, break out of the loop
                break;
            } else {
                console.warn('Received empty response. Retrying...');
            }
        } catch (error) {
            console.error('Error fetching camera data:', error);
        }

        // Increment the retry count and wait for a short duration before retrying
        retries++;
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
    }

    return data;
};
