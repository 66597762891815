import React, { useEffect, useState } from 'react'
import '../styles/dashboard.css'
import question from '../static/pictures/question.png'

import {getToken} from '../functions/loginVerification'
import { logout } from '../functions/logout'
import { addDaily } from '../functions/addDaily'

import { useNavigate } from 'react-router-dom'
import { addInformation } from '../functions/addInformation'
import { addEvent } from '../functions/addEvent'
import { getEvents } from '../functions/getEvents'
import { filterEvents } from '../functions/filterEvents'
import { addArchivePhotos } from '../functions/addArchivePhotos'
import { addDolezite, addImportant } from '../functions/addImportant'
import { getAllInstructors } from '../functions/getAllInstructors'
import { addInstructor } from '../functions/addInstructor'
import { deteleInstructors } from '../functions/deleteInstructor'
import { seasonChecking } from '../functions/seasonChecking'

const Dashboard = () => {

    const navigate = useNavigate();

    const [response, setResponse] = useState([]);
    const [dashChoice, setDashChoice] = useState(0);
    const [deleteDash, setDeleteDash] = useState(0);
    const [deleteDashChoice, setDeleteDashChoice] = useState(1);
    
    const [header, setHeader] = useState("");
    const [text, setText] = useState("");

    const [eventName, setEventName] = useState("");
    const [eventText, setEventText] = useState("");
    const [eventPics, setEventPics] = useState([]);
    const [eventStart, setEventStart] = useState("");

    const [dailyText, setDailyText] = useState("");

    const [message, setMessage] = useState("");
    const [allEvents, setAllEvents] = useState([]);
    const [choosingPodujatia, setChoosingPodujatia] = useState(1);
    const [chosenPodujatie, setChosenPodujatie] = useState([]);
    const [podujatiePics, setPodujatiePics] = useState([]);

    const [doletizeHeader, setDoleziteHeader] = useState("");
    const [doleziteText, setDoleziteText] = useState("");

    const [instruktori, setInstruktori] = useState([]);
    const [instruktorName, setInstruktorName] = useState("");
    const [instruktorTelephone, setInstruktorTelephone] = useState("");
    const [instruktorZameranie, setInstruktorZameranie] = useState("Lyžovanie");
    const [messageDelete, setMessageDelete] = useState("");

    const isSummer = seasonChecking();

    useEffect(() => {
        const dashboardOpen = async() => {
            const data = await getToken();
            setResponse(data);
        }
        const events = async() => {
            const allEvents = await getEvents();
            const filteredOld = filterEvents(allEvents.data, 1, allEvents.data.length);
            setAllEvents(filteredOld);
        }

        const OnStart = async() => {
            const instruktori = await getAllInstructors();
            setInstruktori(instruktori.instructors);
        }
        
        dashboardOpen();
        events();
        OnStart();
    }, []);

    const selectDashChoice = (choice) => {
        if(choice === 0){
            setDashChoice(0);
            setMessage("");
        }
        else if(choice === 1){
            setDashChoice(1);
            setMessage("");
        }
        else if(choice === 2){
            setDashChoice(2);
            setMessage("");
        }
        else if(choice === 3){
            setDashChoice(3);
            setMessage("");
        }
        else if(choice === 4){
            setDashChoice(4);
            setMessage("");
        } else if(choice === 9){
            setDashChoice(9);
            setMessage("");
        }
        else if(choice === 10){
            setDashChoice(10);
            setMessage("");
        }
        else if(choice === 5){
            setDashChoice(1);
            setDeleteDash(1);
            setMessage("");
        }
        else if(choice === 6){
            setDeleteDashChoice(1);
            setDeleteDash(0);
            setMessage("");
        }
        else if(choice === 7){
            setDeleteDashChoice(1);
            setMessage("");
        }
        else if(choice === 8){
            setDeleteDashChoice(2);
            setMessage("");
        }
    }

    const formInfo = async(e) => {
        e.preventDefault();
        if(header.length !== 0 && text.length !== 0) {
            const response = await addInformation(header, text);
            setMessage(response.message);
        } else setMessage("Nedostatok informácii!");
    }

    const formDaily = async(e) => {
        e.preventDefault();
        if(dailyText.length !== 0) {
            const response = await addDaily(dailyText);
            setMessage(response.message);
        } else setMessage("Nedostatok informácii!");
    }

    const formEvent = async(e) => {
        e.preventDefault();
        setMessage("");
        if(eventName.length !== 0 && eventText.length !== 0) {
            const formData = new FormData();
            formData.append("eventName", eventName);
            formData.append("eventText", eventText);
            formData.append("startDate", eventStart);
            for(let x = 0; x < eventPics.length; x++) {
                formData.append("file", eventPics[x]);
            }
            const response = await addEvent(formData);
            setMessage(response.message);
        } else setMessage('Nedostatok informacii!');
    }

    const podujatieArchivFotky = async(e) => {
        e.preventDefault();
        setMessage("");
        if(podujatiePics.length !== 0) {
            const formData = new FormData();
            formData.append("id", chosenPodujatie.id);
            for(let x = 0; x < podujatiePics.length; x++) {
                formData.append("file", podujatiePics[x]);
            }
            const response = await addArchivePhotos(formData);
            setMessage(response.message);
        } else setMessage('Je potrebné pridať fotky!');
    }

    const formDolezite = async(e) => {
        e.preventDefault();
        setMessage("");
        if(doletizeHeader.length !== 0 || doleziteText.length !== 0) {
            const response = await addImportant(doletizeHeader, doleziteText);
            setMessage(response.message);
        } else setMessage('Je potrebné zadať údaje');
    }

    const formDeleteInfo = async(e) => {
        //e.preventDefault();
        setMessage("");
        const response = await formDeleteInfo();
        setMessage(response.message);
    }

    const formInstruktori = async(e) => {
        e.preventDefault();
        setMessage("");
        if(instruktorName.length > 2 && instruktorTelephone.length > 2) {
            const response = await addInstructor(instruktorName, instruktorTelephone, instruktorZameranie);
            setMessage(response.message);
        } else setMessage("Nedostatok informácii!");
    }

    const deleteInstructor = async(id) => {
        setMessageDelete("");
        const response = await deteleInstructors(id);
        setMessageDelete(response.message);
    }


  return (
    <div className="dashboard-page">
        {response.ok ? <>
            {deleteDash === 0 ?
            <div className="dash-bar">
                <div className="header"><h1>Administrácia<br/><small style={{fontSize: '17px'}}>{isSummer ? 'Summer season' : 'Winter season'}</small></h1></div>
                <div className="bar-option" onClick={()=>selectDashChoice(1)}><h3>Pridať informáciu</h3></div>
                <div className="bar-option" onClick={()=>selectDashChoice(2)}><h3>Pridať podujatie</h3></div>
                <div className="bar-option" onClick={()=>selectDashChoice(3)}><h3>Pridať fotky</h3></div>
                <div className="bar-option" onClick={()=>selectDashChoice(4)}><h3>Pridať denné info</h3></div>
                <div className="bar-option" onClick={()=>selectDashChoice(9)}><h3>Pridať dôležité</h3></div>
                <div className="bar-option" onClick={()=>selectDashChoice(10)}><h3>Inštruktori</h3></div>
                <div className="bar-option" onClick={()=>selectDashChoice(11)}><h3>Otváranie</h3></div>
                <div className="bar-option" onClick={()=>selectDashChoice(5)} style={{background: 'grey'}}><h3>Možnosti odstraňovania</h3></div>
                <div className="bar-option" onClick={async()=>{await logout(navigate, '/')}}><h3>Odhlásiť sa</h3></div>
            </div> : 
            <div className="dash-bar">
                <div className="header"><h1>Administrácia</h1></div>
                <div className="bar-option" onClick={()=>selectDashChoice(7)}><h3>Odstrániť najnovšie info</h3></div>
                <div className="bar-option" onClick={()=>selectDashChoice(8)}><h3>Odstrániť najnovšie podujatie</h3></div>
                <div className="bar-option" onClick={()=>selectDashChoice(6)} style={{background: 'grey'}}><h3>Späť na pridávanie</h3></div>
                <div className="bar-option" onClick={async()=>{await logout(navigate, '/')}}><h3>Odhlásiť sa</h3></div>
            </div> }
            {dashChoice === 0 && deleteDash === 0 ?
            <div className="content">
                <div className="header"><h1>Vitaj, niko125</h1></div>
                <div className="information">
                    <img src={question} alt="exclamation"/>
                    <p>Vitaj v administrácii. K tejto časti stránky majú prístup iba administrátori. V tomto prostredí môžeš pridávať na svoju stránku rôzne udalosti, informácie, apod.. Vyber položku z ľavého panelu a začni pridávať!</p>
                </div>
            </div>
            : dashChoice === 1 && deleteDash === 0 ?
            <div className="content">
                <div className="header"><h1>Pridať informáciu</h1></div>
                <div className="login-form">
                    <form method="POST" onSubmit={(e)=>formInfo(e)}>
                        <label for="infoHeader">Názov informácie</label>
                        <input type="text" onChange={(e)=>setHeader(e.target.value)}  id="infoHeader" name="infoHeader" placeholder="napr. Lyžiarske stredisko znova otvorené!"/>

                        <label for="infoText">Text</label><br/>
                        <textarea onChange={(e)=>setText(e.target.value)}></textarea>
                        
                        <p>Informácia sa zobrazí na hlavnej stránke. Najnovšie dve pridané informácie sa zobrazujú na hlavnej stránke, ostatné sú filtrované!</p>
                        <p style={{color: 'lightgreen'}}>{message}</p>
                        <div style={{textAlign: 'center'}}><input type="submit" value="Pridaj informáciu"/></div>
                    </form>
                </div>
            </div> 
            : dashChoice === 2 && deleteDash === 0 ? 
            <div className="content">
                <div className="header"><h1>Pridať podujatie</h1></div>
                <div className="login-form">
                    <form method="POST" onSubmit={(e)=>formEvent(e)} encType="multipart/form-data">
                        <label for="infoHeader">Názov poduajtia</label>
                        <input type="text" onChange={(e)=>setEventName(e.target.value)}  id="infoHeader" name="infoHeader" placeholder="napr. Príbehy pri ohni"/>

                        <label for="infoText">Popis podujatia</label><br/>
                        <textarea onChange={(e)=>setEventText(e.target.value)}></textarea><br/>
                        
                        <label for="infoHeader">Dátum začiatku podujatia</label>
                        <input type="text" onChange={(e)=>setEventStart(e.target.value)}  id="infoHeader" name="infoHeader" placeholder="napr. 22.3.2022"/>


                        <label for="file">Pridaj fotky pre podujatie:</label>
                        <input style={{color: 'white'}} onChange={(e)=>setEventPics(e.target.files)} type="file" multiple="multiple" name="file" id="file" />
                        
                        <p>Podujatia sa zobrazujú v zostupnom štýle. Najnovšie je vždy prvé, pod ním budú pridané fotky.</p>
                        <p style={{color: 'lightgreen'}}>{message}</p>
                        <div style={{textAlign: 'center'}}><input type="submit" value="Pridaj podujatie"/></div>
                    </form>
                </div>
            </div>
            : dashChoice === 3 && deleteDash === 0 ? 
            <div className="content">
                <div className="header"><h1>Pridať fotky do archívu</h1></div>
                {choosingPodujatia === 1 ?<><p style={{color: 'white', textAlign: 'center', marginTop: '-15px'}}>Ako prvé vyberte z archívu podujatie, do ktorého chcete pridávať</p>
                <table id="events-table" style={{margin: '0 auto 20px auto'}}>
                    <tr>
                        <th style={{paddingLeft: '10px'}}>Názov podujatia</th>
                    </tr>
                    {allEvents.map((event, index) => <tr onClick={()=>{setChosenPodujatie(event); setChoosingPodujatia(0)}} tabIndex={index}><td>{event.header}</td></tr>)}
                </table></> 
                :  <>
                    <p style={{color: 'white', textAlign: 'center', marginTop: '-15px'}}>Pridajte fotky k tomuto podujatiu</p>
                    <p style={{color: 'gray', textAlign: 'center'}}>Vybraté podujatie: {chosenPodujatie.header}</p>
                    
                    <div className="login-form" style={{width: '50%'}}>
                        <form method="POST" style={{textAlign: 'center'}} onSubmit={(e)=>podujatieArchivFotky(e)} encType="multipart/form-data">
                            <label for="file">Pridaj fotky pre podujatie:</label>
                            <input style={{color: 'white'}} onChange={(e)=>setPodujatiePics(e.target.files)} type="file" multiple="multiple" name="file" id="file" />
                            
                            <p style={{textAlign: 'center'}}>Pridané fotky sa zobrazia v danom podujatí.</p>
                            <p style={{color: 'lightgreen', textAlign: 'center'}}>{message}</p>
                            <div style={{textAlign: 'center'}}><input type="submit" value="Pridaj fotky"/></div>
                        </form>
                    </div>      
                   </>
                }
            </div>
            : dashChoice === 4 && deleteDash === 0 ? <div className="content">
            <div className="header"><h1>Pridať dennú informáciu</h1></div>
            <div className="login-form">
                <form method="POST" onSubmit={(e)=>formDaily(e)}>
                    <label for="infoText">Text</label><br/>
                    <textarea onChange={(e)=>setDailyText(e.target.value)}></textarea>
                    
                    <p>Denná informácia sa zobrazí v bočnom paneli stránky v rámčeku.</p>
                    <p style={{color: 'lightgreen'}}>{message}</p>
                    <div style={{textAlign: 'center'}}><input type="submit" value="Pridaj dennú informáciu"/></div>
                </form>
            </div>
        </div> 
        : dashChoice === 9 && deleteDash === 0 ? 
        <div className="content">
            <div className="header"><h1>Pridať dôležité info</h1></div>
            <div className="login-form">
                    <form method="POST" onSubmit={(e)=>formDolezite(e)} encType="multipart/form-data">
                        <label for="infoHeader">Názov dôležitého infa</label>
                        <input type="text" onChange={(e)=>setDoleziteHeader(e.target.value)}  id="infoHeader" name="infoHeader" placeholder="napr. V sobotu je porada"/>

                        <label for="infoText">Text doležitého infa</label><br/>
                        <textarea onChange={(e)=>setDoleziteText(e.target.value)}></textarea><br/>
                
                        <p style={{color: 'lightgreen'}}>{message}</p>
                        <div style={{textAlign: 'center'}}><input type="submit" value="Pridaj dôležité"/></div>
                    </form>
            </div>
        </div>
        : dashChoice === 10 && deleteDash === 0 ? 
        <div className="content">
            <div className="header"><h1>Inštruktori</h1></div>
            <div className="login-form">
                    <form method="POST" onSubmit={(e)=>formInstruktori(e)} encType="multipart/form-data">

                        <label for="infoHeader">Meno inštruktora</label>
                        <input type="text" onChange={(e)=>setInstruktorName(e.target.value)}  id="infoHeader" name="infoHeader" placeholder="napr. Jozef"/>

                        <label for="infoText">Telefón inštruktora</label><br/>
                        <input type="text" onChange={(e)=>setInstruktorTelephone(e.target.value)}  id="infoHeader" name="infoHeader" placeholder="napr. +421 985 478 998"/>
                
                        <label for="cars">Vyber zameranie:</label>
                        <select name="cars" onChange={(e)=>setInstruktorZameranie(e.target.value)} id="cars" form="carform">
                            <option value="Lyžovanie">Lyžovanie</option>
                            <option value="Snowboarding">Snowboarding</option>
                        </select>
                        <p style={{color: 'lightgreen'}}>{message}</p>
                        <div style={{textAlign: 'center'}}><input type="submit" value="Pridaj inštruktora"/></div>
                    </form>
            </div>
            <h2 style={{textAlign: 'center', color: 'white'}}>Vymazať inštruktora</h2>
            <p style={{color: 'gray', textAlign: 'center'}}>Kliknutím v tabuľke na inštruktora sa daný inštruktor vymaže.</p>
            <p style={{color: 'lightgreen', textAlign: 'center'}}>{messageDelete}</p>
            <table id="events-table" style={{margin: '20px auto 20px auto', textAlign: 'center'}}>
                <tr>
                    <th style={{paddingLeft: '10px'}}>Meno</th>
                    <th style={{paddingLeft: '10px'}}>Číslo</th>
                    <th style={{paddingLeft: '10px'}}>Zameranie</th>
                </tr>
                {instruktori.map((instruktor, index) => <tr onClick={()=> deleteInstructor(instruktor.id)} tabIndex={index}>
                    <td>{instruktor.meno}</td>
                    <td>{instruktor.telefon}</td>
                    <td>{instruktor.zameranie}</td>
                </tr>)}
            </table>
        </div>
        : deleteDash === 1 && deleteDashChoice === 1 ? 
        <div className="content">
            <div className="header"><h1>Odstrániť najnovšie info</h1></div>
            <div className="login-form">
                <form method="DELETE" onSubmit={()=>formDeleteInfo()}>
                    <p style={{textAlign: 'center'}}>Kliknutím na tlačidlo nižšie súhlasíte s odstránením najnovšej vami pridanej informácie na serveri.</p>
                    <p style={{color: 'lightgreen'}}>{message}</p>
                    <div style={{textAlign: 'center'}}><input type="submit" value="Odstrániť informáciu"/></div>
                </form>
            </div>
        </div> : deleteDash === 1 && deleteDashChoice === 2 ? <div className="content">
            <div className="header"><h1>Odstrániť najnovšie podujatie</h1></div>
            <div className="login-form">
                <form onSubmit={(e)=>formDaily(e)}>
                    <p style={{textAlign: 'center'}}>Kliknutím na tlačidlo nižšie súhlasíte s odstránením najnovšieho vami pridaného podujatia na serveri.</p>
                    <p style={{color: 'lightgreen'}}>{message}</p>
                    <div style={{textAlign: 'center'}}><input type="submit" value="Odstrániť podujatie"/></div>
                </form>
            </div>
        </div> : ""}
        </> : ""}
    </div>
  )
}

export default Dashboard