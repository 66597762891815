import axios from 'axios'
import { getToken } from './loginVerification'

export const getImportant = async() => {
    const tokenData = await getToken();
    let data;

    await axios.get(`${process.env.REACT_APP_API_HOST}/important/get-important`).then(resp => {
        data = resp.data
    });

    return data;
}