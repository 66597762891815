import React from 'react'
import '../styles/home.css'
import {fixedDate} from '../functions/dates'
import { seasonChecking } from '../functions/seasonChecking'

const isSummer = seasonChecking();

const Information = ({color, header, text, date}) => {
  return (
    <div className="content">
        {color === 1 ? <h2 style={isSummer ? {color: '#84d9ad'} : {color: '#25aae1'}}>{header}</h2> : <h2 style={{color: '#ff6174'}}>{header}</h2>}
        <p>{text}</p>
        <small>Pridané dňa: {fixedDate(date)}</small>
    </div>
  )
}

export default Information