import axios from 'axios'
import { getToken } from './loginVerification'

export const addInstructor = async(name, telefon, zameranie) => {
    const tokenData = await getToken();
    let data;
    if(tokenData.ok) {
        const instance = axios.create({
            headers: {'Authorization': 'Bearer '+ tokenData.token}
        });
        await instance.post(`${process.env.REACT_APP_API_HOST}/instructors/add-instructor`, {
            name: name,
            telefon: telefon,
            zameranie: zameranie
        }).then(resp => {
            data = resp.data;
        });
        return data;
    }
}