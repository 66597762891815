
function Otvorenie() {
    return (
        <table class="otvorenie styled-table">
            <tbody>
                <tr>
                    <td>PO</td>
                    <td>9:00 - 16:00</td>
                </tr>
                <tr>
                    <td>UT</td>
                    <td>9:00 - 16:00</td>
                </tr>
                <tr>
                    <td>ST</td>
                    <td>9:00 - 16:00</td>
                </tr>
                <tr>
                    <td>ŠT</td>
                    <td>9:00 - 16:00</td>
                    <td>17:00 - 20:00</td>
                </tr>
                <tr>
                    <td>PI</td>
                    <td>9:00 - 16:00</td>
                    <td>17:00 - 20:00</td>
                </tr>
                <tr>
                    <td>SO</td>
                    <td>9:00 - 16:00</td>
                    <td>17:00 - 20:00</td>
                </tr>
                <tr>
                    <td>NE</td>
                    <td>9:00 - 16:00</td>
                </tr>
            </tbody>
        </table>

    )

}

export default Otvorenie