import axios from 'axios'
/*
    Funkcia vracia filrované udalosti (aktualne, neaktualne)
    status = 0 - aktualne, 1 - neaktualne (uplynule)
    num = how many you want to show filtered
    returns Array of filtered events
*/

export const filterEvents = (eventArray, status, num) => {
    const filteredArray = new Array();
    let added = 0;
        for (let x = 0; x < eventArray.length; x++) {
            if(added < num) {
                if(status === 0 ? new Date(eventArray[x].startDate).getTime() > new Date().getTime() 
                : status === 1 ? new Date(eventArray[x].startDate).getTime() < new Date().getTime() : null) {
                    filteredArray.push(eventArray[x]);
                    added++;
                }
            } else break;
    }
    return filteredArray;
}