import axios from 'axios'
/*
    Funkcia vracia filtrovaných inštruktorov (buď snowboarding alebo lyžovanie podľa parametra)
    instructorArray - súbor inštruktorov (pole)
    job - 0 = lyžovanie, 1 = snowboarding
*/

export const filterInstructors = (instructorArray, job) => {
    const filteredArray = new Array();
    instructorArray.forEach(instruktor => {
        if(job === 0) {
            if(instruktor.zameranie === "Lyžovanie") {
                filteredArray.push(instruktor);
            }
        } else if(job === 1) {
            if(instruktor.zameranie === "Snowboarding") {
                filteredArray.push(instruktor);
            }
        }
    });
    return filteredArray;
}